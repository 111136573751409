<template>

    
    <div>

        <!-- Section Path File/Modul -->
        <v-breadcrumbs 
            :items="pathModul"
            divider="-"
            normal
            class="pathModul"
        ></v-breadcrumbs>

        <!-- Section Filtering Input/Select/Button -->
        <v-container class="container-filtering">

            <v-row align="center">
 
                <!-- Column Select Station -->
                <!-- <v-col
                class="d-flex"
                cols="12"
                sm="6"
                md="4"
                > -->
                <v-col
                class="col-input-filter"
                >
                    <v-select
                    v-on:change="selectValueStation"
                    :items="itemStation"
                    label="Station"
                    hint="Station"
                    ></v-select>
                </v-col>

                <!-- Column Select Parameter -->
                <v-col
                class="col-input-filter"
                >
                    <v-select
                    v-model="arraySelectedParam"
                    :items="listParam"
                    label="Parameter"
                    multiple
                    persistent-hint
                    @change="checkedParam($event)"
                    ></v-select>
                </v-col>

                <!-- Column Select Calendar -->
                <v-col
                class="col-input-filter"
                >
                    <v-menu
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    v-model="modalCalendar"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                        v-model="dateFormat"
                        label="Date"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                      v-on:change="selectDate"
                      v-model="dateFormat"
                      @input="modalCalendar = false"
                      type="month"
                      
                      ></v-date-picker>
                    </v-menu>
                </v-col>

                <!-- Column Generate Button -->
                <v-col
                cols="12"
                sm="6"
                md="4"
                class="col-btn-generateReport"
                >
                    <v-btn
                    class="ma-2 white--text btn-generate primary"
                    solo
                    dense
                    normal
                    @click="generateChart"
                    :loading="loadingbut"
                    >
                      Generate Chart & Table
                      <v-icon
                      right
                      dark
                      big
                      >
                          mdi-chart-line
                      </v-icon>
                    </v-btn>
                </v-col>

            </v-row>


        </v-container>
        


        <!-- Section Result Generated Report (TABLE) -->
        <v-container class="container-result-generated">

            <!-- Main Report Title/Tab -->
            <v-tabs>
                <v-tab>MONTHLY</v-tab>
            </v-tabs>


            <!-- Genarate Chart -->
            <highcharts 
            :constructorType="'stockChart'" 
            class="hc" 
            :options="chartOptions" 
            ref="chart"
            >
            </highcharts>

        </v-container>



<!-------------------bawah ni shaz tambah utk table---------------------->

<v-container class="container-result-table" align="center">
  <v-subheader class="subTitle fontSubTitle-blue">
    <!-- Data For {{displayParameterSelected}} ({{displayStationSelected}}) {{dateFrom}} To {{dateTo}} -->
    
  </v-subheader>

  <template>
      <!-- Table -->
      <!-- <v-data-table
        :headers="dtheader"
        :items="dtbody"
        :header-props="{ sortIcon: null }"
        :items-per-page="10"
        fixed-header
        
      >
      </v-data-table> -->

      <v-row justify='end'>
        <v-col class="text-right">
          <v-btn
            color="green"
            elevation="2"
            @click="exportReport('excel')"
          >
            <v-icon color="white" center dark>
              mdi-microsoft-excel
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <template>
        <v-data-table
          :headers="dtheader"
          :items="dtbody"
          :items-per-page="10"
          class="elevation-1 table_header"
          fixed-header
        ></v-data-table>
      </template>


    </template>
</v-container>


<!-------------------end of bawah ni shaz tambah utk table---------------------->


        <template>
          <v-footer padless>
            <v-col
              class="text-center footer-report"
              cols="12"
            >
              <!-- {{ new Date().getFullYear() }} — <strong>Vuetify</strong> -->
              <span class="one-text-footer">
                <span class="red--text">C</span>
                <span class="blue--text">=CALIBRATION</span>
              </span>
              <span class="one-text-footer">
                <span class="red--text">M</span>
                <span class="blue--text">=MAINTENANCE</span>
              </span>
              <span class="one-text-footer dontBreak">
                <span class="red--text">DOR</span>
                <span class="blue--text">=DATA OUT OF SENSOR RANGE</span>
              </span><br>
              <span class="one-text-footer">
                <span class="red--text">OUTL</span>
                <span class="blue--text">=OUTLIER</span>
              </span>
              <span class="one-text-footer">
                <span class="red--text">NA</span>
                <span class="blue--text">=DATA NOT AVAILABLE</span>
              </span>

            </v-col>
          </v-footer>
        </template>

    </div>

    

</template>


<script>

import axios from 'axios';
import Papa from "papaparse";


export default {

    
    data: () => ({
        displayParameterSelected: null,
        GlobalDataMonthly : [],

        pathModul: [
            {
                text: 'Home',
                disabled: false,
                href: '/',
                // href: '/Mapviewer',
            },
            {
                text: 'Report - Monthly',
                disabled: true,
                href: '/Report/MonthlyReport',
            },
        ],
        itemStation: [],
        dateFormat: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 7),
        menu: false,
        modalCalendar: false,
        menu2: false,
        valStation: null,
        valParameter: null,
        valDate: null,

        // Title
        locationStation: null,
        stationID: null,

        

        arraySelectedParam: [],
        // listParam: [
        //   "WQI",
        //   "DO_subIndex",
        //   "DO_concentration",
        //   "DO_saturation",
        //   "BOD_subIndex",
        //   "BOD_concentration",
        //   "COD_subIndex",
        //   "COD_concentration",
        //   "NH3N_subIndex",
        //   "NH3N_concentration",
        //   "TSS_subIndex",
        //   "TSS_concentration",
        //   "pH_subIndex",
        //   "pH_concentration",
        //   "SpCond_concentration",
        //   "Salinity_concentration",
        //   "Temp_concentration",
        //   "Turb_concentration",
        //   "NH4_concentration",
        //   "TOC_concentration",
        //   "CHLOROPHYLL_A_concentration",
        //   "E_COLI_concentration",
        //   "SolarBattery_concentration"
        // ],

        listParam: [],

        chartOptions: {
          // chart: {
          //   type: 'spline'
          // },
          title: {
            text: 'Water Quality Status'
          },
          subtitle: {
            text: ''
          },
          series: [],
          legend: {
              enabled: true
          },
        },
        

        ////shaz add for table
        loadingbut: false,
        dtheader: [
          // {
          //   text: 'Dessert (100g serving)',
          //   value: 'name',
          // },
          // { text: 'Calories', value: 'calories' },
          // { text: 'Fat (g)', value: 'fat' },
          // { text: 'Carbs (g)', value: 'carbs' },
          // { text: 'Protein (g)', value: 'protein' },
          // { text: 'Iron (%)', value: 'iron' },
        ],

        dtbody: [
          // {
          //   name: 'Frozen Yogurt',
          //   calories: 159,
          //   fat: 6.0,
          //   carbs: 24,
          //   protein: 4.0,
          //   iron: '1%',
          // },
          // {
          //   name: 'Ice cream sandwich',
          //   calories: 237,
          //   fat: 9.0,
          //   carbs: 37,
          //   protein: 4.3,
          //   iron: '1%',
          // }
        ]


        ////end of shaz add for table

    }),
    methods: {

      // Anything need to load first refresh
      load(){
        // console.log(this);
        this.getListStation();
        this.getDataParam();
      },

      getListStation(){
        axios.get('https://apis.spatialworks.com.my/sarawak/cms/stations', {
              headers: {
                  'Authorization': 'Bearer ' + this.$store.getters.user.token,
              }
          })
          .then((response) => {
              // console.log(response.data);
              this.itemStation = [];

              for (let i = 0; i < response.data.length; i++) {
                this.itemStation.push(response.data[i].stationId + " - " + response.data[i].location);
              }
              this.itemStation.push('All');  //ni shaz memandai tambah
          })
          .catch(error => {
              console.log(error);
          })
      },

      getDataParam(){
          axios.get('https://apis.spatialworks.com.my/sarawak/cms/params', {
              headers: {
                  'Authorization': 'Bearer ' + this.$store.getters.user.token,
              }
          })
          .then((response) => {
              // this.stationDetails = response.data;
              // console.log(response.data);

              for (let i = 0; i < response.data.length; i++) {
                this.listParam.push(response.data[i].reading);
              }

          })
          .catch(error => {
              console.log(error);
          })
      },

      // Get Data From Api
      getDataMonthly(station,tarikh){
          console.log(station);
          console.log(tarikh);
          //console.log(this.valParameter);
          //return;

          ///kasi error kalo param lebih dari 3 =

          if(this.valParameter.length > 2) 
          {
            alert('Sorry, due to performance issue, you can only select max 2 params only.');
            return;
          }


          this.loadingbut = true;
          axios.get('https://apis.spatialworks.com.my/sarawak/cms/monthly?startdt='+ tarikh +'-01&stationid='+ station + '&paramlist=' +  this.valParameter,{
              headers: {
                  'Authorization': 'Bearer ' + this.$store.getters.user.token,
              }
          })
          .then((response) => {
              // this.stationDetails = response.data;
              // console.log(response.data);

              // Globalkan Data
              this.GlobalDataMonthly = response.data;

              this.loadDataChart(response.data,this.valParameter,station);
              this.loadingbut = false;

          })
          .catch(error => {
              console.log(error);
          })
      },


      // Get value from Selected Station
      selectValueStation: function(e) {
        // console.log(e);
        this.valStation = e.slice(0, 5);
        // console.log(this.valStation);
      },

      // Get value from Selected Parameters
      checkedParam: function(e) {
          console.log(e);
          this.valParameter = e;
          // console.log(this.valParameter);
      },

      // Get value from Selected Date (by Month)
      selectDate: function(e) {
          this.valDate = e;
          // console.log(this.valDate);
      },

      // load Data for Rendering Data Series Highchart
      loadDataChart(data,param,stesen){


        console.log(data);
        console.log(param);
        console.log(this.listParam);

        for (let i = 0; i < data.length; i++) {
            let keyData = Object.keys(data[0])

            // Condition if value enter lest than 0
            // for (let x = 0; x < keyData.length; x++) {
            //     if (data[i][keyData[x]] < 0) {
            //         data[i][keyData[x]] = null;
            //     } 
            // }
        }

        this.dtheader = [];
        for(let i of Object.keys(data[0])){
          if(i != "name" && i != "time" && i != "epochdate" ){
            if(i == "datetime_a"){
              this.dtheader.push({text:"DATETIME",value:i});
            }

            else if(i == "station_a"){
              this.dtheader.push({text:"STATION ID",value:i});
            }

            else{
              this.dtheader.push({text:i,value:i});
            }
          }
        }


        for(let i of data){
          this.dtbody.push(i);
        }
        

        // Clear first chart before generate another new chart
        //this.chartOptions.series = [];  //komen out

        for(let i = 0; i < param.length; i++){
          let obj = {
            name: param[i] + " " + ("["+stesen+"]" ?? ""),
            data: []
          }
          console.log(obj);
          data.forEach(function(res,k){
            obj.data.push([res.epochdate,res[param[i]]]);
          });

          // console.log(obj);
          this.chartOptions.series.push(obj);
        }

        //this.chartOptions.subtitle.text = "Selected Parameters for "+ data[0]["LOCATION"] +" ("+ this.valStation +")"

      },

      // Generate Chart
      generateChart(){
        this.chartOptions.series = [];   //kosongkan
        console.log('this.itemStation = ', this.itemStation);

        this.dtbody = [];
        this.dtheader = [];

        if(this.valStation != "All"){
          this.loadingTable = true;
          this.getDataMonthly(this.valStation,this.dateFormat);
        }
        else{

          for(let i of this.itemStation){
            if(i != "All"){
              this.getDataMonthly( i.split(' ')[0] ,this.dateFormat);  //amik stesen name kt depan
            }
            else continue;
            
          }

        }

      },


      exportReport(){

        var blob = new Blob(
            [Papa.unparse(this.specialExcel(this.dtheader, this.dtbody))],
            { type: "text/csv;charset=utf-8;" }
          );

          var link = document.createElement("a");

          var url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute(
            "download",
            "Monthly report"
          );
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
      },


      handler() {
        var args = arguments;
        for (var arg of args) {
          if (arg instanceof Function) {
            arg();
          }
        }
      },
      
    },
    mounted() {
      
      this.load();
    }

}
</script>



<style>

      /* Section Path File/Modul */
    .pathModul{
      padding-bottom: 0px;
    }

    .pathModul > li:nth-child(3) > a{
      color: black !important;
    }

    /* Section Filtering Input/Select/Button */
    .container-filtering{
      padding: 0px;
    }

    /* .col-input-filter{
      padding: 0px 10px;
    } */

    .col-btn-generateReport{
        margin-bottom: 18px;
    }

    .btn-generate{
        margin: 0px !important;
        /* background: #4495D1 !important; */
    }

    .v-text-field.v-text-field--solo .v-input__control,
    .btn-generate .v-btn .v-btn--contained{
        min-height: 10px;
        margin-top: 10px;
    }

    /* Section Chart Form */
    .highcharts-credits{
      display: none;
    }

    /* Footer Report */
    .one-text-footer{
      margin: 0px 10px;
    }

    .table_header > .v-data-table__wrapper > table > thead > tr > th{
        background-image: linear-gradient(to bottom, #0081BA, #02B3C3) !important;
        color: white !important;
        font-size: 14px !important;
    }

</style>